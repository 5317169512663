.comingsoon_page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(rgba(12, 32, 45, 1), rgba(12, 32, 45, 0.7));
}

.comingsoon_text {
  text-transform: capitalize;
  margin-top: 50px;
  font-size: 25px;
  color: white;
  /* border: solid; */
}

.comingsoon_text span {
  margin-left: 10px;
  font-size: 35px;
}
