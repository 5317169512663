* {
  box-sizing: border-box;
}

.project_section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 60px 0 0 0;
  font-family: "Quattrocento Sans", sans-serif;
  padding: 0px;
  color: #0c202d;
}

.projects_container {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 1300px;
}

.project_title {
  font-size: 40px;
  margin: 60px;
  text-transform: uppercase;
}

.project {
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex;
  /* align-items: center; */
  margin: 25px 10px;
  width: 100%;
  min-width: 310px;
  max-width: 1500px;
  background: #fafff9;
  transition: all 1s;
}

.project:hover {
  box-shadow: 1px 1px 20px lightgrey;
}

.used_tech {
  color: grey;
}

.thumbnail {
  max-width: 700px;
  overflow: hidden;
}

.chart {
  min-width: 700px;
}

.thumbnail img {
  width: 100%;
}
.description {
  display: flex;
  flex-direction: column;
  margin: 5px;
  width: 100%;
}

.description_text {
  flex-grow: 1;
}

.description_text_title {
  font-size: 18px;
  margin: 30px 0;
  /* border-bottom: 1px solid; */
  width: 50%;
}

p,
.project_links {
  margin: 10px 20px;
}

.marianella_credits {
  font-size: 14px;
}

@media all and (max-width: 1299px) {
  .project {
    /* grid-template-rows: 1fr auto;
    grid-template-columns: 1fr;
    justify-items: center; */
    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox; /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Chrome */
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 35px 0px;
  }

  .thumbnail {
    max-width: 750px;
  }

  .description {
    max-width: 750px;
  }
}

@media all and (max-width: 768px) {
  .projects_container {
    margin: 0 5px;
  }

  .chart {
    min-width: 400px;
  }
}
