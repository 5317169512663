.homepage {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  color: grey;
  font-family: "Quattrocento Sans", sans-serif;
  background: linear-gradient(rgba(12, 32, 45, 1), rgba(12, 32, 45, 0.7)),
    url(./images/UGLF2781.JPG);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0;
}

.presentation_position {
  animation: blinking-caret 1s step-end infinite;
  border-right: solid;
  padding-right: 5px;
}

@keyframes blinking-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: grey;
  }
}

@-webkit-keyframes blinking-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: grey;
  }
}

.intro_container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  word-break: break-all;
}

.name {
  color: white;
}

h1 {
  font-size: 40px;
  margin: 5px;
}

.fa-angle-down {
  font-size: 55px;
  margin-top: 45px;
  transition: color 1s, transform 0.6s;
  /* color: #55767d; */
  color: grey;
}

.fa-angle-down:hover {
  color: white;
  cursor: pointer;
}

@media only screen and (max-width: 1200px) {
  h1 {
    font-size: 38px;
  }

  .fa-angle-down {
    font-size: 52px;
  }
}
@media only screen and (max-width: 668px) {
  h1 {
    font-size: 35px;
  }

  .fa-angle-down {
    font-size: 48px;
  }
}

@media only screen and (max-width: 600px) {
  h1 {
    font-size: 30px;
  }

  .fa-angle-down {
    font-size: 41px;
  }
}

@media only screen and (max-width: 550px) {
  h1 {
    font-size: 23px;
  }

  .fa-angle-down {
    font-size: 31px;
  }

  .homepage {
    height: 100vh;
    width: 100%;
  }
}

@media all and (max-width: 375px) {
  .homepage {
    width: 100%;
  }
  h1 {
    font-size: 20px;
  }
}

@media all and (max-width: 320px) {
  h1 {
    font-size: 20px;
  }

  .fa-angle-down {
    font-size: 27px;
  }
}

@media (prefers-color-scheme: dark) {
  .homepage {
    color: grey;
    font-family: "Quattrocento Sans", sans-serif;
    background: linear-gradient(rgba(12, 32, 45, 1), rgba(12, 32, 45, 0.7)),
      url(./images/UGLF2781.JPG);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0;
  }
}
