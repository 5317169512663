* {
  box-sizing: border-box;
}

.tech_logos {
  display: flex;
  justify-content: space-between;
  /* border: solid orange; */
  /* background: #f5f5f5; */
  margin: 20px 10px 60px 10px;
}

.tech_logos > i {
  margin: 20px;
  flex: 1 1 auto;
  font-size: 50px;
  /* border: solid; */
  text-align: center;
}

svg {
  margin: 20px;
  flex: 1 1 auto;
  /* border: solid; */
  height: 56px;
  width: 50px;
}

@media all and (max-width: 940px) {
  .tech_logos > i,
  svg {
    margin: 20px 3px 20px 3px;
  }

  .tech_logos {
    width: 100vw;
  }
}

@media all and (max-width: 640px) {
  .tech_logos > i {
    font-size: 35px;
  }
  svg {
    height: 41px;
    width: 35px;
  }
}

@media all and (max-width: 460px) {
  .tech_logos {
    width: 95vw;
    flex-wrap: wrap;
  }
  .tech_logos > i {
    font-size: 48px;
  }
  svg {
    height: 48px;
    width: 48px;
  }
}

@media all and (max-width: 375px) {
  .tech_logos > i {
    font-size: 25px;
  }
  svg {
    height: 25px;
    width: 25px;
  }
}

@media all and (max-width: 320px) {
  .tech_logos > i {
    font-size: 20px;
  }
  svg {
    height: 20px;
    width: 20px;
  }
}
