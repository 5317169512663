.about_section {
  margin: 60px 10px 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Quattrocento Sans", sans-serif;
}

.profile_picture {
  display: inline-block;
  position: relative;
  width: 200%;
  max-width: 350px;
  align-items: center;
}

.profile_picture::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(12, 32, 45, 0.6), rgba(12, 32, 45, 0));
  mix-blend-mode: multiply;
  pointer-events: none;
}

.profile_picture img {
  display: block;
  width: 100%;
}

.about_title {
  font-size: 40px;
  margin: 60px;
  text-transform: uppercase;
}

.about {
  display: flex;
  max-width: 1000px;
  margin: 10px;
  color: #0c202d;
}

.bio_section {
  display: flex;
  align-items: center;
}

.bio {
  font-size: 18px;
}

@media all and (max-width: 1050px) {
  .profile_picture {
    width: 40%;
    min-width: 300px;
  }
  .about {
    flex-direction: column;
    align-items: center;
  }
  .bio {
    max-width: 500px;
    margin-top: 20px;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 825px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
  .about_section {
    margin: 60px 5px 0 5px;
  }
  .bio_section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 825px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  .about_section {
    margin: 60px 5px 0 5px;
  }
  .bio_section {
    display: flex;
    margin-top: 20px;
    width: 80%;
    justify-content: center;
    align-items: center;
    border-left: solid 0.5px lightgrey;
    border-right: solid 0.5px lightgrey;
  }
}
