* {
  box-sizing: border-box;
}

.contact_container {
  background: #0c202d;
  color: grey;
  font-family: "Quattrocento Sans", sans-serif;
  display: flex;
  flex-direction: column;
  margin: 0;
}

.mock_div {
  height: 450px;
  background: white;
}

.menu_arrows {
  text-align: center;
  font-size: 30px;
  color: grey;
  width: 30px;
  margin: 5px auto;
}

.menu_arrows:hover {
  color: white;
  cursor: pointer;
}

.contact_title {
  text-align: center;
  font-size: 45px;
  margin: 40px 0 40px 0;
  text-transform: uppercase;
}

.contact_logos {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.contact_logos > div {
  margin: 15px;
  border: 1px solid #0c202d;
  background: #283947;
  padding: 10px;
}

.contact_name {
  font-size: 14px;
  color: white;
}

.github,
.linkedin,
.email {
  transition: background 0.7s;
}

.github:hover,
.linkedin:hover,
.email:hover {
  background: white;
}

.github_link,
.github_link:visited,
.linkedin_link,
.linkedin_link:visited,
.email_link,
.email_link:visited {
  color: grey;
}

.github_link:hover {
  color: black;
  cursor: pointer;
}

.linkedin_link:hover {
  color: #0d7eb9;
  cursor: pointer;
}

.email_link:hover {
  color: red;
  cursor: pointer;
}

.fa-github,
.fa-linkedin,
.fa-envelope {
  font-size: 25px;
}

.copyright {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 5px;
  margin-bottom: 5px;
}

.credits {
  font-size: 14px;
}

.credits.mock_element {
  color: #0c202d;
}

@media all and (max-width: 550px) {
  .copyright {
    flex-direction: column;
  }
  .credits {
    margin-top: 5px;
  }
}
