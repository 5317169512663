.nav_bar {
  display: flex;
  background: #0c202d;
  height: 45px;
  align-items: center;
  justify-content: flex-start;
  font-size: 20px;
  font-weight: 100;
  font-family: "Quattrocento Sans", sans-serif;
  transition: height 0.5s;
  color: grey;
  z-index: 999;
}

.nav_bar:hover {
  box-shadow: 3px 3px 20px #0c202d;
  height: 55px;
}

.section_name:hover,
.resume:hover {
  color: #2f5567;
}

.active,
.section_name.on,
.dropdown_sections.on {
  color: #3b8696;
}

.sections {
  margin: 15px 15px 15px 25px;
  text-decoration: none;
  transition: color 0.5s;
  text-transform: uppercase;
}

.sections:hover {
  cursor: pointer;
}

.sections.home {
  margin-left: 60px;
}

.fixed {
  position: fixed;
  width: 100%;
  margin-bottom: 250px;
}

.sections {
  display: flex;
  flex-direction: column;
}

.menu {
  margin-left: 10px;
}

.menu:hover {
  cursor: pointer;
}

.menu > * {
  width: 25px;
  border: solid 2px;
  margin: 4px;
}

/* --------- mobile version ---------*/

.dropdown {
  box-sizing: border-box;
  background: rgba(12, 32, 45, 0.9);
  flex-direction: column;
  font-size: 20px;
  font-weight: 100;
  font-family: "Quattrocento Sans", sans-serif;
  color: grey;
  width: 100%;
}

.dropdown_sections {
  margin: 10px 35px;
  transition: color 0.5s;
  text-transform: uppercase;
}

.dropdown_sections:hover {
  color: #2f5567;
  cursor: pointer;
}

.visible {
  display: flex;
  align-items: flex-end;
  margin-bottom: -210px;
}

.hidden {
  display: none;
}

@media only screen and (max-width: 767px) {
  .nav_bar {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .sections {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .menu {
    display: none;
  }
  .dropdown {
    display: none;
  }
}

/* @media (prefers-color-scheme: dark) {
  .nav_bar {
    background-color: black;
    color: white;
  }
} */
